import supplierRecallService from '../../../../../services/supplier/supplier-recall'
import { Supplier_Disruption_AffectedCustomers_AgGrid, AG_GridCustomizedTableHeaders_Key } from '../../../../../misc/tableHeaders'
import _ from 'lodash'
export default {
    props: ['communicationId', 'recallDisabled', 'infoData'],
    communicationType: {type: String, default: ''},
    data() {
        return {
            columnDefs: null,
            gridOptions: {
                onFilterChanged: this.onFilterChanged,
                onSortChanged: this.onSortChanged,
            },
            rowHeight: 50,
            searchQuery: { pageNumber: 1, pageSize: 50, sort: null, colId: null },
            total: 0,
            items: [],
            status: '',
            gridApi: null,
            columnApi: null,
            search: {
                status: null,
                supplier: null,
            },
            table_Connection_Data: this.$store.state.CustomizedHeaders.filter(s => s.userId == this.$store.state.user.id)[0],
            table_Data: JSON.parse(localStorage.getItem(AG_GridCustomizedTableHeaders_Key))?.filter(s => s.userId == this.$store.state.user.id)[0],
        }
    },
    created() {
        this.rowModelType = 'serverSide'
    },
    beforeMount() {
        this.columnDefs = Supplier_Disruption_AffectedCustomers_AgGrid
    },
    mounted() {
        this.info = this.infoData
    },
    methods: {
        saveState(params) {
            this.table_Connection_Data.Supplier_Login.Recall_AffectedCustomer_Headers = params.columnApi.getColumnState()
            localStorage.setItem(AG_GridCustomizedTableHeaders_Key, JSON.stringify(this.$store.state.CustomizedHeaders))
        },
        applyState(params) {
            var tableState = JSON.parse(localStorage.getItem(AG_GridCustomizedTableHeaders_Key))?.filter(s => s.userId == this.$store.state.user.id)[0]
            var columnState = tableState?.Supplier_Login?.Recall_AffectedCustomer_Headers
            if (columnState) {
                params.columnApi.applyColumnState({ state: columnState, applyOrder: true })
            }
        },
        onFilterChanged(event) {
            this.table_Connection_Data.Supplier_Login.Recall_AffectedCustomer_Filters=event.api.getFilterModel()
            localStorage.setItem(AG_GridCustomizedTableHeaders_Key, JSON.stringify(this.$store.state.CustomizedHeaders))
            this.fetchData(event)
        },
        setFilterModel(params) {
            var tableState = JSON.parse(localStorage.getItem(AG_GridCustomizedTableHeaders_Key))?.filter(s => s.userId == this.$store.state.user.id)[0]
            var filterModel = tableState?.Supplier_Login?.Recall_AffectedCustomer_Filters
            if (filterModel) {
                params.api.setFilterModel(filterModel)
            }
        },
        onSortChanged(event) {
            this.saveState(event)
            this.fetchData(event)
        },
        fetchData(params) {
            this.applyState(params)
            this.setFilterModel(params)
            let that = this
            that.gridApi = params.api
            that.columnApi = params.columnApi
            params.api.sizeColumnsToFit()
            const datasource = {
                getRows(params) {
                    that.locationname = params.request?.filterModel?.location_name?.filter.trim()
                    that.locationid = params.request?.filterModel?.location_id?.filter.trim() == '' ? undefined : params.request?.filterModel?.location_id?.filter
                    that.idn = params.request?.filterModel?.idn?.filter
                    that.address1 = params.request?.filterModel?.address1?.filter
                    that.address2 = params.request?.filterModel?.address2?.filter
                    that.city = params.request?.filterModel?.city?.filter
                    that.state = params.request?.filterModel?.state?.filter
                    that.zipcode = params.request?.filterModel?.zip_code?.filter
                    that.status = params.request?.filterModel?.status?.filter
                    that.searchQuery.colId = params.request?.sortModel[0]?.colId
                    that.searchQuery.sort = params.request?.sortModel[0]?.sort
                    that.search.filterModel = that.gridApi.getFilterModel()
                    supplierRecallService.findSupplierAffectedCustomersBycommunicationId({
                        ...that.searchQuery,
                        ...that.search,
                        sortField: params.request?.sortModel[0]?.colId,
                        sortDirection: params.request?.sortModel[0]?.sort,
                        type: null,
                        communicationId: that.communicationId,
                        locationname: that.locationname,
                        locationid: that.locationid,
                        idn: that.idn,
                        address1: that.address1,
                        address2: that.address2,
                        city: that.city,
                        state: that.state,
                        zipcode: that.zipcode,
                        status: that.status
                    }).then(resp => {
                        if (!resp.error) {
                            const recallsarray = []
                            resp.data.d.c.forEach((x) => {
                                if(x.status == 'NON_COVERED')
                                {
                                    x.status = 'Not Covered'
                                }
                                else if (x.status == 'UNABLE_PROCESSED') {
                                    x.status = 'Unrecognized'
                                }
                                else if (x.status == 'EMAILED')
                                {
                                    x.status = 'Emailed'
                                }
                                else if (x.status == 'VALID')
                                {
                                    x.status = 'Valid'
                                }
                                else if (x.status == 'INVALID')
                                {
                                    x.status = 'Invalid'
                                }
                                else
                                {
                                    x.status = 'Covered'
                                }
                                that.items.push(x)
                                recallsarray.push(x)
                            })
                            that.total = resp.data.d.t
                            if(that.total == 0){
                                that.gridApi.showNoRowsOverlay()
                            }
                            else{
                                that.gridApi.hideOverlay()
                            }
                            return recallsarray
                        }
                    }).then(response => {
                        params.successCallback(response)
                    })
                        .catch(error => {
                            console.error(error)
                            params.failCallback()
                        })
                }
            }
            // register datasource with the grid
            params.api.setServerSideDatasource(datasource)

        },
        onCellClicked(event){

        }
    }
}