import recallServices from '../../../../../services/supplier/supplier-recall'
import { COMMUNICATION_TYPE } from '../../../../../misc/commons'
export default {
    props: {
        communicationId: {type: String, default: null},
        supplierRecallId: {type: String, default: ''},
        communicationType: {type: String, default: ''},
    },
    data() {
        return {
            histories: [],
            communicationTypeForRecall: COMMUNICATION_TYPE.RECALL,
        }
    },
    created(){
        this.GetRecallHistories()
        this.$emit('onReLoadCommunicationData')
    },
    methods: {
        GetRecallHistories () {
            recallServices.GetRecallHistories(this.communicationId).then(resp => {
                if (!resp.error){
                    this.histories = resp.data.d
                }
            })
        },
        downloadChangeHistoryXLSXReport() {
            recallServices.downloadChangeHistoryXLSXReport(this.communicationId, this.supplierRecallId).then(resp => {
                if (resp && resp.error) {
                    this._showToast('Recall change history download failed.', { variant: 'danger' })
                }
            })
        },
    }
}