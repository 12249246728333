import VueDropzone from 'vue2-dropzone'
import _ from 'lodash'
import * as XLSX from 'xlsx'
import recallServices from '../../../../../../services/supplier/supplier-recall'
export default {
    components: {
        VueDropzone
    },
    props: {
        communicationUID: { type: String, default: null },
        communicationID: { type: String, default: null }
    },
    data() {
        return {
            item: {},
            isFileSelect: false,
            dropzoneOptions: {
                url: '#',
                acceptedFiles: '.xlsx,.xls',
                maxFiles: 1,
                maxFilesize: 20,
                previewsContainer: false,
                autoProcessQueue: false
            }
        }
    },
    methods: {
        forceRerenderInsert() {
            this.$refs.modal.show()
        },

        addSuccess(file) {
            this.isFileSelect = false
            this.item = {
                file: null
            }
            if (file.name && _.isArray(file.name.split('.'))) {
                var ext = file.name.split('.')
                if (!['xls','xlsx'].includes(ext[ext.length - 1])) {
                    this._showToast('Only accept xls, xlsx file format', { variant: 'danger' })
                    return
                }
                if (!file.name.includes(this.communicationUID)) {
                    this._showToast('The uploaded file’s Recall ID does not match this recall.', { variant: 'danger' })
                    return
                }

                const reader = new FileReader()
                reader.onload = (e) => {
                    const data = new Uint8Array(e.target.result)
                    const workbook = XLSX.read(data, { type: 'array' })
                    const firstSheetName = workbook.SheetNames[0]
                    const worksheet = workbook.Sheets[firstSheetName]
                    const errors = this.validateWorksheet(worksheet)
                    if(errors.length > 0) {
                        this._showNotify('Validation Summary',errors.join('<br><br>'),'danger',{},'lg')
                    } else {
                        this.item.file = file
                        this.isFileSelect = true
                    }
                }
                reader.readAsArrayBuffer(file)
            }
        },

        validateWorksheet(worksheet) {
            const requiredColumns = ['IDN / Provider Name', 'Location ID', 'Response Date', 'Response Type', 'Responder']
            const validResponseTypes = ['paper', 'phone', 'email']

            // Convert worksheet to JSON array with headers
            const jsonArray = XLSX.utils.sheet_to_json(worksheet, { header: 1, blankrows: false})

            // Extract headers
            const headers = jsonArray[0]

            // Remove the header row from data
            jsonArray.shift()

            // Initialize validation errors array
            this.validationErrors = []

            // Check for missing columns
            requiredColumns.forEach((col) => {
                if (!headers.includes(col)) {
                    this.validationErrors.push(`Missing required column: ${col}`)
                }
            })

            // Check if there are no data rows
            if (jsonArray.length === 0) {
                this.validationErrors.push('No data rows found.')
            } else {
                // Column indices based on headers
                const columnIndices = requiredColumns.map(col => headers.indexOf(col))

                // Validate each row
                jsonArray.forEach((row, rowIndex) => {

                    const rowErrors = []

                    columnIndices.forEach((colIndex, i) => {
                        if (colIndex !== -1 && (!row[colIndex] || row[colIndex].toString().trim() === '')) {
                            rowErrors.push(`${requiredColumns[i]} cannot be blank`)
                        }
                    })

                    // Validate Response Type
                    const responseTypeIndex = headers.indexOf('Response Type')
                    if (responseTypeIndex !== -1) {
                        const responseType = row[responseTypeIndex]?.trim().toString().toLowerCase()
                        if (!responseType || !validResponseTypes.includes(responseType)) {
                            rowErrors.push(`Response Type must be one of ${validResponseTypes.join(', ')}`)
                        }
                    }

                    // If there are errors for the current row, add them to validationErrors
                    if (rowErrors.length > 0) {
                        const formattedErrors = rowErrors.join('<br> • ').replace(/, ([^,]*)$/, ' and $1')
                        this.validationErrors.push(`Row ${rowIndex + 2}: <br> • ${formattedErrors}`)
                    }
                })
            }
            return this.validationErrors
        },

        save() {
            let formData = new FormData()
            formData.append('file', this.item.file)
            recallServices.importPaperResponse(this.communicationID,formData).then((res) => {
                if (!res.error) {
                    if (res.data.d.failed_count === 0 && res.data.d.success_count === 0) {
                        if (res.data.d.errors.length > 0) {
                            this._showNotify('Validation Summary',res.data.d.errors.join('<br>'),'danger',{},'lg')
                        } else {
                            this.$emit('onCompleted', 'UPLOAD')
                            this.$refs.modal.hide()
                        }
                    } else {
                        let messages = []
                        if (res.data.d.success_count > 0) {
                            messages.push(`${res.data.d.success_count} ${res.data.d.success_count === 1 ? 'Record' : 'Records'} imported successfully`)
                        }
                        if (res.data.d.failed_count > 0) {
                            messages.push(`${res.data.d.failed_count} ${res.data.d.failed_count === 1 ? 'Record' : 'Records'} failed to import`)
                        }
                        messages = messages.concat(res.data.d.errors)
                        this._showNotify('Upload Summary',messages.join('<br><br>'),'success',{},'lg')
                        this.$emit('onCompleted', 'UPLOAD')
                        this.$refs.modal.hide()
                    }
                }
            })
        },
    }
}