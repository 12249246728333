import _ from 'lodash'
import recallServices from '../../../../../services/supplier/supplier-recall'
import { RECALL_STATUS, RECALL_STATUS_PROVIDER, RECALL_CLASSIFICATIONS, ER_RECALL_STATUS, AFFECTED_PRODUCT_STATUS, OUTPUT_DATE_FORMAT } from '../../../../../misc/commons'
import PaperModal from './paper-modal'
import ViewModal from '../../../../../components/part/shared/PreviewViewModal'
import PaperResponseImport from './paper-response-import'
export default {
    props: {
        communicationId: {type: String, default: null},
        allow_paperresponse: {type: Boolean, default: true},
        status: {type: String, default: null},
        communicationType: {type: String, default: ''},
        communicationUID: {type: String, default: ''},
    },
    components: {
        PaperModal,
        ViewModal,
        PaperResponseImport
    },
    data() {
        return {
            OUTPUT_DATE_FORMAT: OUTPUT_DATE_FORMAT,
            total: 0,
            timer: null,
            delay: 700,
            clicks: 0,
            items: [],
            paperModalKey: 0,
            viewModalKey: 0,
            paperResponseImportKey: 0,
            showaddmore: true,
            disableRecallPRTemplateBtn: false,
            search: {
                responseStatus: null,
                affectedProduct: null,
                respondent: null,
                supplierOrgId: null,
                supplierOrgName: null,
                status: null,
            },
            searchQuery: {},
            header: [
                {
                    label: 'IDN/Provider Name',
                    name: 'idn',
                    sort: true,
                    row_text_alignment: 'text-left',
                    column_text_alignment: 'text-left',
                },
                {
                    label: 'Number of Locations',
                    name: 'no_loc',
                    sort: true,
                    row_text_alignment: 'text-left',
                    column_text_alignment: 'text-left',
                },
                {
                    label: 'Response Documents',
                    name: 'files',
                    sort: true,
                    row_text_alignment: 'text-left',
                    column_text_alignment: 'text-left',
                },
                {
                    label: 'Responder',
                    name: 'responder',
                    sort: true,
                    row_text_alignment: 'text-left',
                    column_text_alignment: 'text-left',
                },
                {
                    label: 'Response Type',
                    name: 'response_type',
                    sort: false,
                    row_text_alignment: 'text-left',
                    column_text_alignment: 'text-left',
                },
                {
                    label: 'Response Date',
                    name: 'response_date',
                    sort: false,
                    row_text_alignment: 'text-left',
                    column_text_alignment: 'text-left',
                },
                {
                    label: '',
                    name: 'action',
                    row_text_alignment: 'text-center',
                    column_text_alignment: 'text-center',
                }
            ],
        }
    },
    created(){
        this.$emit('onReLoadCommunicationData')
        if(this.allow_paperresponse == false || this.status == 'CLOSED'){
            this.showaddmore = false
        }
        else{
            this.showaddmore = true
        }

        // Disable PR Response Template button if the status is closed
        if(this.status == 'CLOSED'){
            this.disableRecallPRTemplateBtn = true
        }
    },

    methods: {

        getLocations() {
            recallServices.getLocationForPaperResponse(this.communicationId).then((res) => {
                if (!res.error) {
                    if (res.data.d.length == 0 || this.status == 'CLOSED') {
                        this.disableRecallPRTemplateBtn = true
                    } else {
                        this.disableRecallPRTemplateBtn = false
                    }
                }
            })
        },
        onCompleted(type) {
            if (type == 'UPLOAD') {
                this._showToast('Successfully uploaded!')
            }
            this.fetchData({ api: this.gridApi, columnApi: this.columnApi })
        },

        importRecallPaperResponse() {
            this.paperResponseImportKey += 1
            this.$nextTick(() => {
                this.$refs.paperResponseImport.forceRerenderInsert()
            })
        },

        btnRecallPRTemplateBtnClick() {
            recallServices.downloadPaperResponseTemplate(this.communicationId,this.communicationUID)
        },
        addMoreClick() {
            this.paperModalKey += 1
            this.item = []
            this.item.communicationType = this.communicationType
            this.$nextTick(() => {
                this.$refs.paperModal.forceRerenderInsert(this.item)
            })
        },
        viewResponseRecall(item) {
            this.paperModalKey += 1
            item.communicationType = this.communicationType
            this.$nextTick(() => {
                this.$refs.paperModal.forceRerenderView(item)
            })
        },
        EditResponseRecall(item) {
            this.paperModalKey += 1
            item.communicationType = this.communicationType
            this.$nextTick(() => {
                this.$refs.paperModal.forceRerenderEdit(item)
            })
        },
        fetchDataOnSuccess(val) {
            this.fetchData()
            if (val != null && val != undefined && val != '') {
                this._showToast(val, { variant: 'success' })
            }
        },
        fetchData() {
            this.getLocations()
            recallServices.getSupplierPaperResponse({
                ...this.searchQuery,
                sortField: this.searchQuery.sort[0]
                    ? this.searchQuery.sort[0].name
                    : null,
                sortDirection: this.searchQuery.sort[0]
                    ? this.searchQuery.sort[0].order
                    : null,
                ...this.search,
            }, this.communicationId).then((resp) => {

                this.items = resp.data.d.c
                this.total = resp.data.d.t
            })
        },
        async download(dataurl) {
            this.ViewClick(dataurl)
        },
        ViewClick(val) {
            this.viewModalKey += 1
            this.$nextTick(() => {
                this.$refs.viewModal.forceRerenderInsert(val)
            })
        },
        deleteById(list) {
            this._showConfirm('Are you sure to delete the paper response, this cannot be undone?', { title: 'Delete Paper Response', variant: 'danger' }).then(confirm => {
                if (confirm) {
                    recallServices.deletePaperResponse(list.paper_response_id).then(resp => {
                        if (!resp.error) {
                            this.fetchDataOnSuccess('Paper Response deleted successfully')
                            this.$refs.modal.hide()
                        }
                    })
                }
            })
        },
        doubleClick(event) {
            this.clicks++
            if (this.clicks === 1) {
                var self = this
                this.timer = setTimeout(function () {
                    self.clicks = 0
                }, this.delay)
            } else {
                clearTimeout(this.timer)
                this.viewResponseRecall(event)
                this.clicks = 0
            }

        },
    }
}