import supplierRecallService from '../../../../../services/supplier/supplier-recall'
import { Supplier_Disruption_SalesReps_AgGrid, AG_GridCustomizedTableHeaders_Key } from '../../../../../misc/tableHeaders'
import _ from 'lodash'
import ErrorModal from '../../../../share/ErrorModal'
import { EMAIL_STATUS } from '../../../../../misc/commons'
export default {
    components: {
        ErrorModal
    },
    props: ['communicationId'],
    communicationType: {type: String, default: ''},
    data() {
        return {
            columnDefs: null,
            gridOptions: {
                onFilterChanged: this.onFilterChanged,
                onSortChanged: this.onSortChanged,
            },
            rowHeight: 50,
            searchQuery: { pageNumber: 1, pageSize: 50, sort: null, colId: null },
            total: 0,
            errorModalKey: 0,
            emailStatus: EMAIL_STATUS,
            items: [],
            status: '',
            gridApi: null,
            columnApi: null,
            search: {
                status: null,
                supplier: null,
            },
            table_Connection_Data: this.$store.state.CustomizedHeaders.filter(s => s.userId == this.$store.state.user.id)[0],
            table_Data: JSON.parse(localStorage.getItem(AG_GridCustomizedTableHeaders_Key))?.filter(s => s.userId == this.$store.state.user.id)[0],
        }
    },
    created() {
        this.rowModelType = 'serverSide'
    },
    beforeMount() {
        this.columnDefs = Supplier_Disruption_SalesReps_AgGrid
    },
    methods: {
        onCellClicked(params) {
            if (params.colDef.field == 'status') {
                this.errorModel(params.data,'btn')
                return
            }
        },
        saveState(params) {
            this.table_Connection_Data.Supplier_Login.SalesReps_Headers = params.columnApi.getColumnState()
            localStorage.setItem(AG_GridCustomizedTableHeaders_Key, JSON.stringify(this.$store.state.CustomizedHeaders))
        },
        applyState(params) {
            var tableState = JSON.parse(localStorage.getItem(AG_GridCustomizedTableHeaders_Key))?.filter(s => s.userId == this.$store.state.user.id)[0]
            var columnState = tableState?.Supplier_Login?.SalesReps_Headers
            if (columnState) {
                params.columnApi.applyColumnState({ state: columnState, applyOrder: true })
            }
        },
        onFilterChanged(event) {
            this.table_Connection_Data.Supplier_Login.SalesReps_Filters=event.api.getFilterModel()
            localStorage.setItem(AG_GridCustomizedTableHeaders_Key, JSON.stringify(this.$store.state.CustomizedHeaders))
            this.fetchData(event)
        },
        setFilterModel(params) {
            var tableState = JSON.parse(localStorage.getItem(AG_GridCustomizedTableHeaders_Key))?.filter(s => s.userId == this.$store.state.user.id)[0]
            var filterModel = tableState?.Supplier_Login?.SalesReps_Filters
            if (filterModel) {
                params.api.setFilterModel(filterModel)
            }
        },
        onSortChanged(event) {
            this.saveState(event)
            this.fetchData(event)
        },
        fetchData(params) {
            this.applyState(params)
            this.setFilterModel(params)
            let that = this
            that.gridApi = params.api
            that.columnApi = params.columnApi
            params.api.sizeColumnsToFit()
            const datasource = {
                getRows(params) {
                    that.searchQuery.colId = params.request?.sortModel[0]?.colId
                    that.searchQuery.sort = params.request?.sortModel[0]?.sort
                    that.search.filterModel = that.gridApi.getFilterModel()
                    supplierRecallService.findSupplierSalesRepsBycommunicationId({
                        ...that.searchQuery,
                        ...that.search,
                        sortField: params.request?.sortModel[0]?.colId,
                        sortDirection: params.request?.sortModel[0]?.sort,
                        communicationId: that.communicationId,
                    }).then(resp => {
                        if (!resp.error) {
                            const recallsarray = []
                            resp.data.d.c.forEach((x) => {
                                that.items.push(x)
                                recallsarray.push(x)
                            })
                            that.total = resp.data.d.t
                            if(that.total == 0){
                                that.gridApi.showNoRowsOverlay()
                            }
                            else{
                                that.gridApi.hideOverlay()
                            }
                            return recallsarray
                        }
                    }).then(response => {
                        params.successCallback(response)
                    }).catch(error => {
                        console.error(error)
                        params.failCallback()
                    })
                }
            }
            // register datasource with the grid
            params.api.setServerSideDatasource(datasource)

        },
        errorModel(val) {
            if(val.status == this.emailStatus.BOUNCE)
            {
                let data = []
                data.bounce_type = val.bounceType
                data.bounce_sub_type = val.bounceSubType
                data.description = val.description
                data.extra_details = val.extra_details
                this.errorModalKey += 1
                this.$nextTick(() => {
                    this.$refs.errorModal.forceRerenderView(
                        data
                    )
                })
            }
        }
    }
}