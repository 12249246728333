import recallServices from '../../../../../services/supplier/supplier-recall'
import ActionType from '../../../../../components/part/provider/ActionType'
import { EMAIL_STATUS, OUTPUT_DATE_FORMAT, COMMUNICATION_TYPE} from '../../../../../misc/commons'
import ErrorModal from '../../../../share/ErrorModal'
export default {
    props: {
        communicationId: {type: String, default: null},
        communicationType: {type: String, default: ''},
    },
    components: {
        ActionType,
        ErrorModal
    },
    data() {
        return {
            OUTPUT_DATE_FORMAT: OUTPUT_DATE_FORMAT,
            communicationTypeForRecall: COMMUNICATION_TYPE.RECALL,
            emailStatus: EMAIL_STATUS,
            viewBy: null,
            errorModalKey: 0,
            allOpenRows: [],
            allOpenactionListRows: [],
            providerList_Detailschild: [],
            customerList_Detailschild: [],
            actionList_DetailsChild: [],
            actionList: [],
            actionList_Details: [],
            isExpanded: false,
            selectedActionId: null,
            actionListFields: [
                {
                    key: 'description',
                    label: 'Description',
                    sortable: true,
                },
                {
                    key: 'progress',
                    label: 'Progress',
                    class: 'text-center',
                    thStyle: { width: '60px' },
                    sortable: true,
                }
            ],
            actionList_Details_Recalls_Fields: [

                {
                    key: 'provider_name',
                    label: 'Provider',
                    sortable: true
                },
                {
                    key: 'account_type',
                    label: 'Account Type',
                    sortable: true,
                    class: 'text-center',
                    thStyle: { width: '125px' },
                },
                {
                    key: 'data',
                    label: 'Response',
                    sortable: true
                },
                {
                    key: 'modified_at',
                    label: 'Last Modified',
                    sortable: true,
                },
                {
                    key: 'name',
                    label: 'Customer',
                    sortable: true,
                },
                {
                    key: 'isExpanded',
                    label: '',
                    sortable: false,
                }
            ],
            actionList_Details_Disruptions_Fields: [

                {
                    key: 'provider_name',
                    label: 'Provider',
                    sortable: true
                },
                {
                    key: 'data',
                    label: 'Response',
                    sortable: true
                },
                {
                    key: 'modified_at',
                    label: 'Last Modified',
                    sortable: true,
                },
                {
                    key: 'name',
                    label: 'Customer',
                    sortable: true,
                },
                {
                    key: 'isExpanded',
                    label: '',
                    sortable: false,
                }
            ],
            actionList_Details_Recalls_FieldsChild: [

                {
                    key: 'actionDesc',
                    label: 'Description',
                    sortable: true
                },
                {
                    key: 'account_type',
                    label: 'Account Type',
                    sortable: true,
                    class: 'text-center',
                    thStyle: { width: '125px' },
                },
                {
                    key: 'data',
                    label: 'Response',
                    sortable: true
                },
                {
                    key: 'modified_at',
                    label: 'Last Modified',
                    sortable: true,
                },
                {
                    key: 'name',
                    label: 'Customer',
                    sortable: true,
                },
            ],
            actionList_Details_Disruptions_FieldsChild: [

                {
                    key: 'actionDesc',
                    label: 'Description',
                    sortable: true
                },
                {
                    key: 'data',
                    label: 'Response',
                    sortable: true
                },
                {
                    key: 'modified_at',
                    label: 'Last Modified',
                    sortable: true,
                },
                {
                    key: 'name',
                    label: 'Customer',
                    sortable: true,
                },
            ],
            providerList: [],
            customerList: [],
            applicationProviderList: [],
            providerList_Details: [],
            customerList_Details: [],
            selectedProviderId: null,
            selectedEmailAddressId: null,
            selectedProviderEmail: null,
            selectedProviderIdNew: null,
            selectedEmailAddressIdNew: null,
            selectedname: null,
            selectedProviderEmailNew: null,
            selectedProviderRecallId: null,
            customerListFields: [
                {
                    key: 'accountName',
                    label: 'Affected Customers',
                    sortable: true
                },
                {
                    key: 'status',
                    label: 'Status',
                    sortable: false,
                    thStyle: { width: '60px' },
                },
                {
                    key: 'completed',
                    label: 'Tasks Completed',
                    class: 'text-center',
                    thStyle: { width: '60px' },
                    sortable: true,
                }
            ],
            customerChildListFields: [
                {
                    key: 'accountName',
                    label: 'Customers',
                    sortable: true
                },
                {
                    key: 'status',
                    label: 'Status',
                    sortable: false,
                    thStyle: { width: '60px' },
                },
                {
                    key: 'completed',
                    label: 'Tasks Completed',
                    class: 'text-center',
                    thStyle: { width: '60px' },
                    sortable: true,
                }
            ],
            providerListFields: [
                {
                    key: 'name',
                    label: 'Related NotiSphere Users',
                    sortable: true
                },
                {
                    key: 'status',
                    label: 'Status',
                    sortable: false,
                    thStyle: { width: '60px' },
                },
                {
                    key: 'completed',
                    label: 'Tasks Completed',
                    class: 'text-center',
                    thStyle: { width: '60px' },
                    sortable: true,
                }
            ],
            providerList_Details_Fields: [
                {
                    key: 'description',
                    label: 'Description',
                    sortable: true
                },
                {
                    key: 'data',
                    label: 'Response',
                    sortable: true
                },
                {
                    key: 'modified_at',
                    label: 'Last Modified',
                    sortable: true,
                },
                {
                    key: 'name',
                    label: 'Customer',
                    sortable: true,
                },
                {
                    key: 'isExpanded',
                    label: '',
                    sortable: false,
                }
            ],
            customerList_Details_Fields: [
                {
                    key: 'description',
                    label: 'Description',
                    sortable: true
                },
                {
                    key: 'data',
                    label: 'Response',
                    sortable: true
                },
                {
                    key: 'modified_at',
                    label: 'Last Modified',
                    sortable: true,
                },
                {
                    key: 'name',
                    label: 'Customer',
                    sortable: true,
                },
                {
                    key: 'isExpanded',
                    label: '',
                    sortable: false,
                }
            ],
            providerList_Details_Fieldschild: [
                {
                    key: 'description',
                    label: 'Description',
                    sortable: true,
                    tdClass: 'DescriptioncolClass',
                },
                {
                    key: 'data',
                    label: 'Response',
                    sortable: true,
                    tdClass: 'responsecolClass',
                },
                {
                    key: 'modified_at',
                    label: 'Last Modified',
                    sortable: true,
                    tdClass: 'modifiedcolClass',
                },
                {
                    key: 'name',
                    label: 'Customer',
                    sortable: true,
                    tdClass: 'usercolClass',
                },
            ],

            PROVIDER_RECALL_STATUS: [
                { value: null, text: 'Pending', color: '#ffc107' },
                { value: 'In Progress', text: 'In Progress', color: '#ffc107' },
                { value: 'OPEN', text: 'In Progress', color: '#ffc107' },
                { value: 'COMPLETED', text: 'Completed', color: '#28a745' },
                { value: 'ERROR', text: 'Error', color: '#DC3545' },
                { value: 'Error', text: 'Error', color: '#DC3545' },
                { value: 'Bounce', text: 'Error', color: '#DC3545' },
                { value: 'Complaint', text: 'Error', color: '#DC3545' },
                { value: 'Sent', text: 'Sent', color: '#28a745' },
                { value: 'Send', text: 'Sent', color: '#28a745' },
                { value: 'Open', text: 'Opened', color: '#28a745' },
                { value: 'Click', text: 'Click', color: '#28a745' },
                { value: 'DELIVERY', text: 'Delivered', color: '#28a745' },
                { value: 'Delivery', text: 'Delivered', color: '#28a745' },
                { value: 'Delivered', text: 'Delivered', color: '#28a745' },
                { value: 'DELIVERED', text: 'Delivered', color: '#28a745' },
                { value: 'BOUNCE', text: 'Error', color: '#DC3545' }
            ],
            AFFECTED_CUSTOMER_STATUS: [
                { value: null, text: 'Pending', color: '#ffc107' },
                { value: 'In Progress', text: 'In Progress', color: '#ffc107' },
                { value: 'OPEN', text: 'In Progress', color: '#ffc107' },
                { value: 'COMPLETED', text: 'Completed', color: '#28a745' },
                { value: 'ERROR', text: 'Error', color: '#DC3545' },
                { value: 'Error', text: 'Error', color: '#DC3545' },
                { value: 'Bounce', text: 'Error', color: '#DC3545' },
                { value: 'Complaint', text: 'Error', color: '#DC3545' },
                { value: 'Sent', text: 'Sent', color: '#ffc107' },
                { value: 'Send', text: 'Sent', color: '#ffc107' },
                { value: 'Open', text: 'Opened', color: '#ffc107' },
                { value: 'Click', text: 'Click', color: '#ffc107' },
                { value: 'DELIVERY', text: 'Delivered', color: '#ffc107' },
                { value: 'Delivery', text: 'Delivered', color: '#ffc107' },
                { value: 'Delivered', text: 'Delivered', color: '#ffc107' },
                { value: 'DELIVERED', text: 'Delivered', color: '#ffc107' },
                { value: 'BOUNCE', text: 'Error', color: '#DC3545' }
            ],
        }
    },
    created(){
        this.$emit('onReLoadCommunicationData')
    },
    mounted() {
        this.viewBy = 'Provider'
    },
    watch: {
        viewBy(val) {
            if (val == 'Action') {
                this.GetRecallAction()
            } else
            if (val == 'Provider') {
                this.GetRecallAction_ProviderListing()
            }
        }
    },
    methods: {
        handleRowClicked(item, index) {
            if(item.isCustomizedContent)
            {
                item.isExpanded=item.isCustomizedContent ?!item.isExpanded:item.isExpanded
            }
            if(item.isCustomizedContent)
            {
                this.allOpenRows.map((ele) => {
                    if (ele.id !== item.id && ele._showDetails) {
                        this.$set(ele, '_showDetails', !ele._showDetails)
                    }
                })
                this.allOpenRows = []
                this.$set(item, '_showDetails', !item._showDetails)
                this.allOpenRows.push(item)
            }
        },
        handleRowClickedActionList(item)
        {
            if(item.isCustomizedContent  && item.baseActionId==0)
            {
                item.isExpanded=item.isCustomizedContent ?!item.isExpanded:item.isExpanded
                this.allOpenactionListRows.map((ele) => {
                    if (ele.id !== item.id && ele._showDetails) {
                        this.$set(ele, '_showDetails', !ele._showDetails)
                    }
                })
                this.allOpenactionListRows = []
                this.$set(item, '_showDetails', !item._showDetails)
                this.allOpenactionListRows.push(item)
            }
        },

        rowTableProviderClick(item) {
            if(!item.isExpandShow && (item.response_status == 'Bounce' || item.response_status == 'ERROR'))
            {
                let data = []
                data.bounce_sub_type = item.bounceSubType
                data.bounce_type = item.bounceType
                data.description = item.description
                data.extra_details = item.extra_details
                this.errorModalKey += 1
                this.$nextTick(() => {
                    this.$refs.errorModal.forceRerenderView(
                        data
                    )
                })
            }
            this.selectedProviderId = item.provider_id
            this.selectedProviderEmail = item.emailAddress
            this.selectedProviderRecallId = item.providerCommunicationId
            if(item.isExpandShow)
            {
                item.isExpand = !item.isExpand
                if(this.allOpenRows.length)
                {
                    this.allOpenRows.map((ele) => {
                        if (ele.id !== item.id && ele._showdetails) {
                            if (ele.id !== item.id && ele._showdetails) {
                                this.$set(ele, '_showDetails', !ele._showdetails)
                            }
                        }
                    })
                }
                this.allOpenRows = []
                this.$set(item, '_showDetails', !item._showDetails)
                this.allOpenRows.push(item)
            }
            else
            {
                this.rowTableCustomerClick(item)
            }
        },
        rowChildTableProviderClick(item) {
            if(item.response_status == 'Bounce' || item.response_status == 'ERROR')
            {
                let data = []
                data.bounce_sub_type = item.bounceSubType
                data.bounce_type = item.bounceType
                data.description = item.description
                data.extra_details = item.extra_details
                this.errorModalKey += 1
                this.$nextTick(() => {
                    this.$refs.errorModal.forceRerenderView(
                        data
                    )
                })
            }
            this.selectedProviderId = item.provider_id
            this.selectedProviderEmail = item.emailAddress
            this.selectedProviderRecallId = item.providerCommunicationId
            this.GetRecallAction_ProviderListing_Detail()
            item.isExpand = !item.isExpand
            if(this.allOpenRows.length)
            {
                this.allOpenRows.map((ele) => {
                    if (ele.emailAddress !== item.emailAddress && ele._showdetails) {
                        if (ele.emailAddress !== item.emailAddress && ele._showdetails) {
                            this.$set(ele, '_showdetails', !ele._showdetails)
                        }
                    }
                })
            }
            this.allOpenRows = []
            this.$set(item, '_showdetails', !item._showdetails)
            this.allOpenRows.push(item)
        },
        rowTableActionClick(item) {
            this.selectedActionId = item.action_id
            this.GetRecallAction_Detail()
        },
        rowClassProvider(item, type) {
            if (!item || type !== 'row') {
                return
            }
            if (item.emailAddress === this.selectedEmailAddressId) {
                return 'table-primary'}
        },
        rowClassSecondProvider(item, type) {
            if (!item || type !== 'row') {
                return
            }
            if (item.name === this.selectedname) {
                return 'table-primary'}
        },
        rowClassAction(item, type) {
            if (!item || type !== 'row') return
            if (item.action_id === this.selectedActionId) return 'table-primary'
        },
        GetRecallAction () {
            recallServices.GetRecallAction(this.communicationId).then(resp => {
                if (!resp.error) {
                    this.actionList = resp.data.d
                    if (this.actionList && this.actionList[0] && this.actionList[0].action_id){
                        this.selectedActionId = this.actionList[0].action_id
                        this.GetRecallAction_Detail()
                    }
                }
            })
        },
        GetRecallAction_Detail () {
            recallServices.GetRecallAction_Detail(this.communicationId, this.selectedActionId).then(resp => {
                if (!resp.error) {
                    this.actionList_Details = resp.data.d.filter(s=>s.baseActionId==0)
                    this.actionList_DetailsChild =resp.data.d.filter(s=>s.baseActionId>0)
                    this.actionList_Details.forEach(item => {
                        if(!item.isCustomizedContent)
                        {
                            if (item.data.valueSingleChoice == undefined && item.data.valueNumeric == undefined && item.data.valueMultiChoice == undefined && item.data.valueAcknowledge == undefined && item.data.valueYesNo == undefined && item.data.valueText == undefined && item.data.valueImage == undefined && item.data.valueDocument == undefined && item.data.valueCheckbox == undefined && item.type != 'Acknowledge') {
                                item.account_type = null
                            }
                        }
                        else
                        {
                            let isResponded = false
                            let child= this.actionList_DetailsChild.filter(s=>s.provider_id==item.provider_id && s.providerCommunicationId ==  item.providerCommunicationId)
                            child.forEach(childitem => {
                                if (childitem.data.valueSingleChoice == undefined && childitem.data.valueNumeric == undefined && childitem.data.valueMultiChoice == undefined && childitem.data.valueAcknowledge == undefined && childitem.data.valueYesNo == undefined && childitem.data.valueText == undefined && childitem.data.valueImage == undefined && childitem.data.valueDocument == undefined && childitem.data.valueCheckbox == undefined) {
                                    childitem.account_type = null
                                }
                                else
                                {
                                    isResponded = true
                                }
                            })
                            if(!isResponded && item.type != 'Acknowledge')
                            {
                                item.account_type = null
                            }
                        }
                    })
                }
            })
        },
        GetRecallAction_ProviderListing () {
            recallServices.GetRecallAction_ProviderListing(this.communicationId).then(resp => {
                if (!resp.error) {
                    this.providerList = resp.data.d.filter(x => x.isCustomer == true)
                    let customerDuplicateCheck = []
                    this.customerList = []
                    this.providerList.forEach(childitem => {
                        childitem.isExpandShow = (resp.data.d.filter(x => x.accountName == childitem.accountName).length > 1) ? true : false
                        let cnt = 0
                        let comcnt = 0
                        if(childitem.isExpandShow)
                        {
                            let isCompleted = true
                            let isError = false
                            let combinedStatus = []
                            resp.data.d.filter(x => x.accountName == childitem.accountName).forEach(childitem1 => {
                                cnt = cnt + childitem1.task
                                comcnt = comcnt + childitem1.completed
                                if(childitem1.status != 'COMPLETED')
                                {
                                    isCompleted = false
                                }
                                if(childitem1.response_status == 'ERROR' || childitem1.response_status == 'Bounce')
                                {
                                    isError = true
                                }
                            })
                            if(isError)
                            {
                                resp.data.d.filter(x => x.accountName == childitem.accountName).forEach(childitem2 => {
                                    childitem2.grandStatus = 'ERROR'
                                })
                            }
                            else
                            {
                                resp.data.d.filter(x => x.accountName == childitem.accountName).forEach(childitem2 => {
                                    combinedStatus.push(childitem2.status == 'COMPLETED' ? childitem2.status : 'In Progress')
                                })
                                resp.data.d.filter(x => x.accountName == childitem.accountName).forEach(childitem2 => {
                                    childitem2.grandStatus =  childitem2.grandStatus == 'ERROR' ? childitem2.grandStatus : Array.from(new Set(combinedStatus)).length == 1 ? Array.from(new Set(combinedStatus))[0] : 'In Progress'
                                })
                            }
                            childitem.grandTotal = cnt
                            childitem.completedTotal = comcnt
                        }
                        else
                        {
                            childitem.grandTotal = childitem.task
                            childitem.completedTotal = childitem.completed
                        }
                        if(!customerDuplicateCheck.includes(childitem.accountName))
                        {
                            childitem.isExpand = false, childitem._showdetails = false
                            customerDuplicateCheck.push(childitem.accountName)
                            this.customerList.push(childitem)
                        }
                    })
                    this.applicationProviderList = []
                    let providerDuplicateCheck = []
                    resp.data.d.forEach(childitem => {
                        if(!providerDuplicateCheck.includes(childitem.name) && !childitem.isCustomer)
                        {
                            childitem.grandStatus = childitem.status
                            this.applicationProviderList.push(childitem)
                            providerDuplicateCheck.push(childitem.name)
                        }
                    })
                    if (this.providerList && this.providerList[0] && this.providerList[0].provider_id){
                        this.selectedProviderId = this.providerList[0].provider_id
                        this.selectedEmailAddressId = this.providerList[0].emailAddress
                        this.selectedProviderRecallId = this.providerList[0].providerCommunicationId
                        this.GetRecallAction_ProviderListing_Detail('Customer',this.providerList[0].provider_id,this.providerList[0].providerCommunicationId, this.providerList[0].emailAddress)
                    }
                }
            })
        },
        rowTableCustomerClick (item, index, event, field) {
            if(item.response_status == 'Bounce' || item.response_status == 'ERROR')
            {
                let data = []
                data.bounce_sub_type = item.bounceSubType
                data.bounce_type = item.bounceType
                data.description = item.description
                data.extra_details = item.extra_details
                this.errorModalKey += 1
                this.$nextTick(() => {
                    this.$refs.errorModal.forceRerenderView(
                        data
                    )
                })
            }
            this.selectedProviderId = item.provider_id
            this.selectedEmailAddressId = item.emailAddress
            this.selectedProviderRecallId = item.providerCommunicationId
            this.GetRecallAction_ProviderListing_Detail('Customer',item.provider_id,item.providerCommunicationId, item.emailAddress)
        },
        rowSecondTableProviderClick (item) {
            if(item.response_status == 'Bounce' || item.response_status == 'ERROR')
            {
                let data = []
                data.bounce_sub_type = item.bounceSubType
                data.bounce_type = item.bounceType
                data.description = item.description
                data.extra_details = item.extra_details
                this.errorModalKey += 1
                this.$nextTick(() => {
                    this.$refs.errorModal.forceRerenderView(
                        data
                    )
                })
            }
            this.selectedProviderIdNew = item.provider_id
            this.selectedEmailAddressIdNew = item.emailAddress
            this.selectedname = item.name
            this.selectedProviderRecallIdNew = item.providerCommunicationId
            this.GetRecallAction_ProviderListing_Detail('Provider',item.provider_id,item.providerCommunicationId, item.emailAddress)
        },
        GetRecallAction_ProviderListing_Detail (mode,selectedProviderId, selectedProviderRecallId,selectedEmailAddressId) {

            recallServices.GetCustomerAction(this.communicationId, selectedProviderId,selectedProviderRecallId, selectedEmailAddressId).then(resp => {
                if (!resp.error) {
                    var actions=[]
                    resp.data.d.filter(s=>s.baseActionId==0).forEach((action) => {
                        if(action.isCustomizedContent==false)
                        {
                            actions.push(action)
                        }
                        else{
                            let clidlActions = resp.data.d.filter(x => x.baseActionId == action.action_id)
                            if(clidlActions.length>0)
                            {
                                actions.push(action)
                            }
                        }

                    })
                    if(mode == 'Provider')
                    {
                        this.providerList_Details = actions
                    }
                    else
                    {
                        this.customerList_Details = actions
                    }
                    if(mode == 'Provider')
                    {
                        this.providerList_Detailschild=resp.data.d.filter(s=>s.baseActionId>0)
                    }
                    else
                    {
                        this.customerList_Detailschild=resp.data.d.filter(s=>s.baseActionId>0)
                    }
                }
            })
        },
        GetCustomerAction_ProviderListing_Detail () {

            recallServices.GetRecallAction_ProviderListing_Detail(this.communicationId, this.selectedProviderIdNew,this.selectedProviderRecallIdNew).then(resp => {
                if (!resp.error) {
                    var actions=[]
                    resp.data.d.filter(s=>s.baseActionId==0).forEach((action) => {
                        if(action.isCustomizedContent==false)
                        {
                            actions.push(action)
                        }
                        else{
                            let clidlActions = resp.data.d.filter(x => x.baseActionId == action.action_id)
                            if(clidlActions.length>0)
                            {
                                actions.push(action)
                            }
                        }

                    })
                    this.providerList_Details = actions
                    this.customerList_Detailschild=resp.data.d.filter(s=>s.baseActionId>0)
                }
            })
        },
        exportproviderstausReport() {
            recallServices.getRecallById(this.communicationId).then(resp => {
                if (resp.error) {
                    this.$router.push({ name: 'supp_Recall' })
                } else {
                    recallServices.exportproviderstausReport(this.communicationId, resp.data.d.communicationUID)
                }
            })
        },
        downloadproviderstausReport() {
            recallServices.getRecallById(this.communicationId).then(resp => {
                if (resp.error) {
                    this.$router.push({ name: 'supp_Recall' })
                } else {
                    recallServices.downloadproviderstausReport(this.communicationId, resp.data.d.communicationUID)
                }
            })
        },
        exportproviderLocationstausReport(){
            recallServices.getRecallById(this.communicationId).then(resp => {
                if (resp.error) {
                    this.$router.push({ name: 'supp_Recall' })
                } else {
                    recallServices.exportproviderLocationstausReport(this.communicationId, resp.data.d.communicationUID)
                }
            })
        },
        downloadproviderLocationstausReport() {
            recallServices.getRecallById(this.communicationId).then(resp => {
                if (resp.error) {
                    this.$router.push({ name: 'supp_Recall' })
                } else {
                    recallServices.downloadproviderLocationstausReport(this.communicationId, resp.data.d.communicationUID)
                }
            })
        },
        expandMoreLess(item) {
            item.isExpand = !item.isExpand
            if (this.isExpanded == false) this.moreLess = 'See Less Information'
            else this.moreLess = 'See More Information'
        },
    }
}